var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideNotification,
          expression: "hideNotification"
        }
      ],
      staticClass: "px-4 self-center"
    },
    [
      _c(
        "div",
        {
          staticClass: "text-teal-400  text-base no-underline cursor-pointer",
          attrs: { id: "notification" },
          on: { click: _vm.toggleNotification }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "font-bold text-xl",
            attrs: { icon: _vm.faBell }
          }),
          _vm._v(" "),
          _vm.unreadNotification
            ? _c("font-awesome-icon", {
                staticClass: "text-red-500 text-xs absolute top-0 mt-2 -ml-1",
                attrs: { icon: _vm.faCircle, "aria-hidden": "true" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.notificationShown
        ? _c(
            "div",
            {
              staticClass:
                "absolute bg-white w-64 mt-5 mr-8 py-4 shadow-lg rounded z-50",
              staticStyle: { right: "5%" }
            },
            [
              _vm._l(_vm.notifications, function(notification) {
                return _vm.notifications.length > 0
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "flex flex-row items-center list-reset px-4 py-2 text-gray-600 no-underline block",
                        attrs: { href: "#" }
                      },
                      [
                        _c("img", {
                          staticClass: "w-10 h-10 rounded-full mr-2",
                          attrs: {
                            src: _vm.generateUrl(
                              notification.data.subject.avatar
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "py-1 text-sm" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "no-underline hover:underline text-blue-400",
                                attrs: {
                                  href:
                                    "/users/" +
                                    notification.data.subject.username
                                }
                              },
                              [_vm._v(_vm._s(notification.data.subject.name))]
                            ),
                            _vm._v(
                              "\n          " +
                                _vm._s(notification.data.action) +
                                "\n          "
                            ),
                            notification.data.url
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "no-underline hover:underline text-blue-400",
                                    attrs: { href: notification.data.url }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(notification.data.object_name)
                                    )
                                  ]
                                )
                              : _c(
                                  "a",
                                  { staticClass: "no-underline text-blue-400" },
                                  [
                                    _vm._v(
                                      _vm._s(notification.data.object_name)
                                    )
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1 text-xs" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(notification.date) +
                                "\n        "
                            )
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.notifications.length === 0
                ? _c(
                    "div",
                    { staticClass: "px-4 py-2 text-sm text-gray-600 block" },
                    [_vm._v("No unread notifications. You're all caught up")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "block border-t" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "list-reset px-4 pt-2 text-blue-400 text-center no-underline block",
                  attrs: { href: "/notifications" }
                },
                [_vm._v("\n      View All\n    ")]
              )
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }