var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-4 h-full border-l flex items-center" }, [
    _c(
      "div",
      {
        staticClass: "text-teal-400  text-base no-underline cursor-pointer",
        on: { click: _vm.showMessageBox }
      },
      [
        _c("font-awesome-icon", {
          staticClass: "font-bold text-xl",
          attrs: { icon: _vm.faEnvelope }
        }),
        _vm._v(" "),
        _vm.unreadMessage
          ? _c("font-awesome-icon", {
              staticClass: "text-red-500 text-xs absolute top-0 mt-2 -ml-1",
              attrs: { icon: _vm.faCircle, "aria-hidden": "true" }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }