var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4 border-l flex items-center cursor-pointer p-4" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.hideProfileDropdown,
              expression: "hideProfileDropdown"
            }
          ],
          staticClass: "flex flex-row items-center",
          attrs: { id: "profile-dropdown" },
          on: { click: _vm.toggleProfileDropdown }
        },
        [
          _c("img", {
            staticClass: "w-10 h-10 rounded-full md:mr-2",
            attrs: { src: _vm.generateUrl(_vm.user.avatar) }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "text-gray-800 text-base no-underline hidden md:block"
            },
            [
              _vm._v("\n      " + _vm._s(_vm.user.name) + "\n      "),
              _c("font-awesome-icon", { attrs: { icon: _vm.faAngleDown } })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.profileDropdownShown
        ? _c(
            "div",
            {
              staticClass:
                "absolute bg-white w-48 right-0 mr-2 py-1 shadow-lg rounded z-50",
              staticStyle: { top: "3.5rem" },
              attrs: { id: "profile-menu" }
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-teal-500 hover:text-white no-underline text-gray-600 block font-medium",
                  attrs: { href: _vm.profileUrl }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1",
                        attrs: { icon: _vm.faUser }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm._f("localize")("Your Profile")) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-teal-500 hover:text-white text-gray-600 font-medium no-underline block",
                  attrs: { href: "/admin" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1 font-regular",
                        attrs: { icon: _vm.faShieldAlt }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      " + _vm._s(_vm._f("localize")("Admin")) + "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-teal-500 hover:text-white text-gray-600 font-medium no-underline block",
                  on: { click: _vm.showTimer }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1 font-regular",
                        attrs: { icon: _vm.faStopwatch }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      " + _vm._s(_vm._f("localize")("Timer")) + "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "a",
                    {
                      staticClass:
                        "px-4 py-2 hover:bg-teal-500 hover:text-white text-gray-600 font-medium no-underline block",
                      attrs: { href: "/settings" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-6 inline-block" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pr-1 font-regular",
                            attrs: { icon: _vm.faCog }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm._f("localize")("Settings")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "block border-t" }),
              _vm._v(" "),
              _vm.impersonating
                ? _c(
                    "a",
                    {
                      staticClass:
                        "px-4 py-2 hover:bg-teal-500 hover:text-white text-gray-600 font-medium no-underline block",
                      attrs: { href: "/impersonate/leave" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-6 inline-block" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pr-1 font-regular",
                            attrs: { icon: _vm.faUserMinus }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm._f("localize")("Leave User")) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-teal-500 hover:text-white text-gray-600 font-medium no-underline block",
                  attrs: { href: _vm.url.logout },
                  on: { click: _vm.logoutUser }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1 font-regular",
                        attrs: { icon: _vm.faSignOutAlt }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\n      " + _vm._s(_vm._f("localize")("Logout")) + "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticStyle: { display: "none" },
          attrs: { id: "logout-form", action: _vm.url.logout, method: "POST" }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }